.resumeTitle {
    font-weight: bold;
    font-size: 225%;
    padding: 100px;
    color: #0C4773;
}

section {
    padding-bottom: 80px;
}

#left {
    margin-left: 2em;
    text-align: left;
}

#right {
    margin-right: 2em;
    text-align: right;
}

h2.date {
    color: #333333;
    margin-top: -2px;
}

h3.job {
    font-weight: bold;
    color: #0C4773;
    margin-top: -2px;
}

p.job {
    margin-top: -2px;
}

.box {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    text-align: center;
    line-height: 1;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    max-width: 100%;
    max-height: 100%;
    padding: 20px 40px;
    color: #0C4773;
  }
  
  html,
  body {
    height: 100%;
    width: 100%;
  }
  
  .container {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  
