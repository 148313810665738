.abtTitle {
    font-family: 'Avenir';
    font-weight: bold;
    font-size: 225%;
    padding: 100px;
    color: #0C4773;
}

section {
    padding-bottom: 80px;
}

#wrapper {
    margin: 1em 2em;
    overflow: hidden;
}

#first-single {
    float: left;
}

#first {
    width: 49%;
    float: left;
}

#second {
    width: 49%;
    float: left;
}

.left {
    text-align: left;
}