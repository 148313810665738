@font-face {
  font-family: 'Avenir';
  src: local('AvenirNextLTPro-Regular'),
    url('Fonts/AvenirNextLTPro-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: local('Comfortaa-Light'),
    url('Fonts/Comfortaa-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

html {
  background-color: #F1F2F2;  
}

body {
  min-height:100%;
  min-width:100%;
  text-align: center;
  line-height: 1.5;
  font-family: 'Comfortaa';
  max-width: 1570px;
  margin-left: auto;
  margin-right: auto;
  background-color: #F1F2F2;
  padding-bottom: 2.5rem;
  position: relative;
}

header {
  margin: 0;
  border-bottom: 2px solid black;
  padding: .5em 2em;
}
header h1 {
  color: black;
  margin: 0;
  padding: 0;
  font-family: 'Avenir';
}
header p {
  margin: 0;
  padding: .25em 0 0 0;
}
header ul {
  margin: 0;
  padding: 1em 0 0 0;
}

main {
  margin: 0;
  margin-top: 80px;
  margin-bottom: auto;
  padding: .5em 2em .25em;
}

h1 {
  text-align: center;
  font-size: 175%;
  margin: 0;
  padding: .5em 0 .25em;
  font-family: 'Avenir';
}
h2 {
  text-align: center;
  font-size: 120%;
  margin: 0;
  padding: .25em 0 .25em ;
}
h1, h2 {
  color: black;
}

p {
  text-align: center;
  margin: .5em 0 .5em 0;
  padding: 0 0 10px;
}

ul {
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
  padding: 0;
}

details > *:not(summary){
  margin-left: 2em;
}

nav ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
nav ul li {
  padding-bottom: 0.5em;
}

a {
  color: #0C4773;
  font-weight: bold;

}
a:hover {
  color: #105f9c;
}

a.footer {
  color: #F1F2F2;
  font-weight: normal;

}
a.footer:hover {
  color: #d8d8d8;
}

br {
  clear: left;
}

table {
  margin: 0 auto;
  border: 1.5px solid #333333;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #333333;
  padding: .2em .5em .2em .5em;
  text-align: center;
  white-space: nowrap;
}

tr:nth-child(even) {
  background-color: #ebebeb
}

th {
  background-color: #0C4773;
  font-weight: normal;
  color: #F1F2F2;
}

tr:hover {
  background-color: #b4b4b4;
}

::selection {
  background: #0C4773;
  color: #F1F2F2;
}