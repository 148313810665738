footer {
    margin-top: 300px;
    padding-right: 45%;
    padding-bottom: 5px;
    border-top: 2px solid #0C4773;
    height: 50px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #0C4773;
  }
  
footer p {
  color: #F1F2F2;
  font-family: 'Avenir';
  text-align: center;
  margin: 1em 2em 0;
}

