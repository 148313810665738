#left {
    margin: 1em 2em;
    text-align: left;
}

#right {
    margin-right: 2em;
    text-align: right;
}

#wrapper {
    margin: 1em 16em;
    overflow: hidden;
}

#first-single {
    float: left;
}

#first {
    width: 49%;
    float: left;
}

#second {
    width: 49%;
    float: left;
}

ul {
    margin-top: 1em;
    margin-left: 3em;
}

hr {
    border: 5px solid #0C4773;
    border-radius: 10px;
}
.Title {
    font-weight: bold;
    font-size: 250%;
    padding-top: 0.5em;
    padding-bottom: 1px;
    margin-bottom: 1px;
    color: #0C4773;
}

h2.Subtitle {
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 170%;
    color: #333333;
}

.box {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    text-align: center;
    line-height: 1;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    max-width: 100%;
    max-height: 100%;
    padding: 20px 40px;
    color: #0C4773;
}

html,
body {
    height: 100%;
    width: 100%;
}

.container {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}