.center-content {
    justify-content: center;
}

.padded {
    padding: 0.5rem 0;
}

.top {
    padding: 0 1rem;
}