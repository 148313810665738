.home {
    color: #0C4773;
    margin-bottom: -25px;
    font-family: 'Avenir';
}

.reduce {
    margin-bottom: -15px;
    font-family: 'Avenir';
}

.slider {
    margin-top: -35px;
}