#left {
    margin: 1em 2em;
    text-align: left;
}

#right {
    margin-right: 2em;
    text-align: right;
}

#wrapper {
    margin: 1em 16em;
    overflow: hidden;
}

#first-single {
    float: left;
}

#first {
    width: 49%;
    float: left;
}

#second {
    width: 49%;
    float: left;
}

ul {
    margin-top: 1em;
    margin-left: 3em;
}

hr {
    border: 5px solid #0C4773;
    border-radius: 10px;
}
.Title {
    font-weight: bold;
    font-size: 250%;
    padding-top: 300px;
    padding-bottom: 1px;
    margin-bottom: 1px;
    color: #0C4773;
}

h2.Subtitle {
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 170%;
    color: #98A4AF;
}